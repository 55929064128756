<script setup>
import { onMounted } from "vue";
import { setPageTitle } from "../../utils/pageTitle";
import TransportOrders from "@/components/transport/TransportOrders.vue";
import { useRouter } from "vue-router";

const router = useRouter();

onMounted(() => {
  setPageTitle("Transportation Orders");
});

</script>

<template>
  <div id="transport-grid-wrapper">
    <TransportOrders />
  </div>
</template>

<style scoped>
#transport-grid-wrapper {
  height: 100%;
  padding-top: 0.25%;
}
</style>
