<script setup>

import { ref, onMounted } from "vue";
import debounce from "lodash/debounce";
import useClients from '@/hooks/useClients';

const props = defineProps({
  gridApi: Object
});

const clientName = ref(null);
const { clients, fetchClients } = useClients();

const clientUrlParams = {
    filter: {
        name: {
        type: "contains",
        filter: "",
        },
    },
    sort: [
        {
        sort: "asc",
        colId: "name",
        },
    ],
    limit: 500,
};

onMounted(async () => {

  await fetchClients(clientUrlParams);

  const filterModelClient = props.gridApi.getColumnFilterModel('client.name');

  if (filterModelClient) {
    clientName.value = filterModelClient.filter;
  }

  const filterModelStatus = props.gridApi.getColumnFilterModel('order_status.name');

  if (!filterModelStatus) {
    statusFilter.value.values.filter(val => val.label === 'All').map(val => val.active = true);
  } else {
    statusFilter.value.values.filter(val => val.value.length === 1 && val.value[0] === filterModelStatus.values[0]).map(val => val.active = true);
  }
});

const statusFilter = ref({

  filter: {
    field: "order_status.name",
  },
  values: [
    {
      label: "All",
      value: ["Pending", "At Warehouse", "New", "Delivered","Out for Pickup","Shipped","Canceled"],
      active: false
    },
    { label: "Not Available", value: ["New"], active: false },
    { label: "Pending Pickup", value: ["Pending"], active: false },
    { label: "Out for Pickup", value: ["Out for Pickup"], active: false },
    { label: "At Warehouse", value: ["At Warehouse"], active: false },
    { label: "Out for Delivery", value: ["Shipped"], active: false },
    { label: "Delivered", value: ["Delivered"], active: false },
    { label: "Canceled", value: ["Canceled"], active: false },
  ],
});

const applySetFilter = async (filter, values) => {
  await props.gridApi.setColumnFilterModel(filter.field, { values });
  props.gridApi.onFilterChanged();
};

const applyTextFilter = async (field, filter) => {

  await props.gridApi.setColumnFilterModel(field, {
    filterType: 'text',
    type: 'startsWith',
    filter: filter,
  });

  props.gridApi.onFilterChanged();
}

const debouncedApplyTextFilter = debounce(applyTextFilter, 500);
const debouncedApplySetFilter = debounce(applySetFilter, 500);

const applyStatusFilter = async (filter, values, label) => {

  await debouncedApplySetFilter(filter, values);

  statusFilter.value.values.map(status => status.active = false);

  statusFilter.value.values.filter(status => {
    return status.label === label;
  }).map(status => status.active = true);
}

const debouncedFetchClients = debounce(async (query) => {
    clientUrlParams.filter.name.filter = query;
    await fetchClients(clientUrlParams);
}, 300);


</script>

<template>
  <div class="w-25">

    <v-card class="mx-auto pa-2 w-100">

      <v-autocomplete v-model="clientName" density="compact" :items="clients" label="Filter Client"
        @update:search="(query) => debouncedFetchClients(query)" 
        @update:model-value="debounce(() => debouncedApplyTextFilter('client.name', clientName), 500)()" 
        item-title="name" item-value="name" hide-details
        variant="outlined">
      </v-autocomplete>

      <v-list v-if="statusFilter">

        <v-list-subheader>STATUS</v-list-subheader>

        <v-list-item v-for="(status, i) in statusFilter.values" :key="i" :value="status.value"
          @click="applyStatusFilter(statusFilter.filter, status.value, status.label)" color="primary" rounded="xl"
          :class="{ 'v-list-item--active': status.active === true }">
          <template v-slot:prepend>
            <v-icon>{{ status.active ? 'mdi-folder-open' : 'mdi-folder' }}</v-icon>
          </template>

          <v-list-item-title v-text="status.label"></v-list-item-title>
        </v-list-item>
      </v-list>
    </v-card>
  </div>
</template>

<style scoped>
.v-list-item--active {
  color: #007bea;
  background-color: #b4c7d6;
}
</style>