<script setup>
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-material.min.css";
import { ref, watch, onBeforeMount } from "vue";
import { AgGridVue } from "ag-grid-vue3";
import { LicenseManager } from "ag-grid-enterprise";
import {
    saveGridState,
    loadGridState,
    connectToServerApi
} from "@/utils/agGridUtils";
import { shortDateWithTimeTz } from "../../utils/dateUtils";
import { getDefaultColDefs, getDefaultGridOptions } from '../../utils/aggridCommonConfigs.js';
import status from '../../components/schedule/statusRenderer.vue';
import { useThemeStore } from "@/stores/themeStore";
import ResetColumnComponent from "../../components/common/aggrid/ResetColumnComponent.vue";
import Warehouses from '../../components/common/Warehouses.vue';
import { useRouter } from 'vue-router';
import TransportFilter from "./TransportFilter.vue";
import {TRANSPORT_ORDERS_ROUTE} from "@/constants/constants.js";

LicenseManager.setLicenseKey(import.meta.env.VITE_AG_GRID_LICENSE_KEY);

const themeStore = useThemeStore();
const OUTBOUND_STAGED_URL = "/transport_orders";
const GRID_SAVE_KEY = "transport_orders_grid_state";
const TRANSPORT_EXTERNAL_FILTERS_KEY = "transport_filters";
const rowSelection = ref(null);
const showFilters = ref(true);
const gridLoaded = ref(false);

let gridApi;
let context = ref(null);
const route = useRouter();
const router = useRouter();

onBeforeMount(() => {
    let filters = JSON.parse(window.localStorage.getItem(TRANSPORT_EXTERNAL_FILTERS_KEY));
    showFilters.value = filters?.showFilters;
});

const onGridReady = (params) => {
    gridApi = params.api;
    context.value = {
        apiUrl: OUTBOUND_STAGED_URL,
        filterKey: TRANSPORT_EXTERNAL_FILTERS_KEY,
        advancedFilterKeys: advancedFilterKeys
    };
    rowSelection.value = 'multiple';
    loadGridState(GRID_SAVE_KEY, gridApi, columnDefs);
    gridLoaded.value = true;
    gridApi.setGridOption("serverSideDatasource", connectToServerApi());
};

const onStateUpdated = function (params) {
    saveGridState(GRID_SAVE_KEY, gridApi);
};

const defaultColDef = getDefaultColDefs();

const advancedFilterKeys = ["id", "client.name", "pick_address", "drop_address", "pick_appt", "drop_appt", "cargo_type", "cargo_qty", "cargo_weight", "order_status.name"];

const getStatusColor = (params) => {

    let color = "grey";

    let status = params.value;
    if (status === 'New') {
        color = "#395F5E";
    } else if (status === 'Pending') {
        color = "#DC7633";
    } else if (status == 'Out For Pickup') {
        color = "#7393B3";
    } else if (status == 'At Warehouse') {
        color = "#6C3483";
    } else if (status == 'Shipped') {
        color = "#8FBC8F";
    } else if (status == 'Delivered') {
        color = "green";
    } else if (status == 'Cancelled') {
        color = "#333";
    }
    return {
        color
    };
}

const statusValueFormatter = (status) => {
    const stateMap = {
        "New": "Not Available",
        "Pending": "Pending Pickup",
        "Shipped": "Out for Delivery"
    };
    let returnValue =  stateMap[status] || status;
    return returnValue;
}

const columnDefs = [
    { field: "id", minWidth: 150, headerName: "Order Id", colId: "id", cellDataType: "text", headerCheckboxSelection: true, checkboxSelection: true },
    { field: "client.name", minWidth: 250, headerName: "Client Name", colId: "client.name", cellDataType: "text", width: "300" },
    { field: "po_number", minWidth: 250, headerName: "PO #", colId: "po_number", cellDataType: "text", width: "300" },
    { field: "pick_address", minWidth: 200, headerName: "Pickup Address", colId: "pick_address", width: "300" },
    { field: 'drop_address', minWidth: 200, headerName: "Drop Off Address", colId: "drop_address", cellDataType: "text", width: "300" },
    {
        field: "pick_appt", minWidth: 210, headerName: "Pickup Date", colId: "pick_appt", cellDataType: "text", width: "200", valueGetter: (params) => {
            return shortDateWithTimeTz(params.data.pick_appt);
        }
    },
    {
        field: "drop_appt", minWidth: 210, headerName: "Drop Off Date", colId: "drop_appt", cellDataType: "text", valueGetter: (params) => {
            return shortDateWithTimeTz(params.data.drop_appt);
        }
    },
    { field: "cargo_type", minWidth: 120, headerName: "Cargo Type", colId: "cargo_type", cellDataType: "text" },
    { field: "cargo_qty", minWidth: 120, headerName: "Quantity", colId: "cargo_qty", cellDataType: "number" },
    { field: "cargo_weight", minWidth: 150, headerName: "Total Weight", colId: "cargo_weight", cellDataType: "number" },
    {
        field: "order_status.name", minWidth: 160, headerName: "Status", colId: "order_status.name", cellDataType: "text",
        filter: 'agSetColumnFilter',
        width: "270",
        filterParams: {
            valueFormatter: (params) => {
                return statusValueFormatter(params.value);
            },
            values: ["At Warehouse", "Pending", "New", "Delivered", "Out for Pickup", "Shipped", "Canceled"],
        },
        cellRenderer: status,
        cellRendererParams: getStatusColor,
        valueGetter: (params) => {
            let status = params.data.order_status.name
            return statusValueFormatter(status);
        }
    }
];

const rowDoubleClicked = function (event) {
    route.push(`/${TRANSPORT_ORDERS_ROUTE}/${event.data.id}`);
};

const gridOptions = getDefaultGridOptions(columnDefs);

watch(showFilters, (showFilters) => {
  saveFiltersToLocalStorage(showFilters);
});

const saveFiltersToLocalStorage = function () {
  let filters = JSON.parse(window.localStorage.getItem(TRANSPORT_EXTERNAL_FILTERS_KEY));
  if (!filters) filters = {};
  filters.showFilters = showFilters?.value;
  window.localStorage.setItem(TRANSPORT_EXTERNAL_FILTERS_KEY, JSON.stringify(filters));
};

</script>

<template>

    <Warehouses :callBack="() => gridApi.onFilterChanged()" :parent-filter="TRANSPORT_EXTERNAL_FILTERS_KEY" />
    <ResetColumnComponent :grid-save-key="GRID_SAVE_KEY" :grid-api="gridApi" />

    <div class="d-flex justify-space-between ml-2">
        <v-btn color="blue" size="small" variant="outlined" @click="showFilters = !showFilters"
            class="filter-btn ml-2 mt-1 mr-2" :append-icon="showFilters ? 'mdi-eye-off-outline' : 'mdi-eye-outline'">{{
        showFilters ? "Hide Filters" :
            "Show Filters" }}</v-btn>

        <div>
            <v-btn size="small" variant="outlined" @click="router.push(`/${TRANSPORT_ORDERS_ROUTE}/loads`)" class="mt-1 mr-4">Manage
                Loads</v-btn>
            <v-btn size="small" variant="outlined" @click="router.push(`/${TRANSPORT_ORDERS_ROUTE}/new`)" class="mt-1 mr-4">New
                Order</v-btn>
        </div>
    </div>
    <div class="pa-4 d-flex">
        <TransportFilter v-if="showFilters && gridLoaded" :grid-api="gridApi" class="mr-4" />
        <AgGridVue style="width: 100%; height: 800px;" :class="themeStore.agTheme" :columnDefs="columnDefs"
            :defaultColDef="defaultColDef" :gridOptions="gridOptions" @grid-ready="onGridReady" :context="context"
            @state-updated="onStateUpdated" :rowSelection="rowSelection" @rowDoubleClicked="rowDoubleClicked">
        </AgGridVue>
    </div>
</template>